class Utils {

	loadSubjects(callback) {

		const site_id = UNIT_INFO.site_id;
		fetch('/api/subjects/?site_id='+site_id)
		.then(response => response.json())
		.then(function(response) {

			callback(response)
		})
	}

	loadUnits(callback) {

		fetch('/dynamics/units/pueri')
		.then(response => response.json())
		.then(function(response) {

			callback(response)
		})
	}

	loadAcknowledgments(callback) {

		fetch('/dynamics/find_out/', {
			method: 'GET'
		}).then(response => response.json())
		.then(function(response) {

			callback(response)
		})
	}

	loadCalendar(unit_id, callback) {

		fetch('/dynamics/calendar/' + unit_id + '/', {
			method: 'GET'
		}).then(response => response.json())
		.then(function(response) {

			callback(response)
		})
	}

	loadSegments(unit_id, callback) {

		fetch('/dynamics/courses/'+unit_id+'/', {
			method: 'GET'
		}).then(response => response.json())
		.then(function(response) {

			callback(response)
		})
	}

	sanitizeText(text) {

		return text.replace(/(<([^>]+)>)/ig,"");
	}

	isElementInViewport(el) {

		var rect = el.getBoundingClientRect();

	  	return (
		    rect.top >= 0 &&
		    rect.left >= 0 &&
		    rect.bottom <= (window.innerHeight || document. documentElement.clientHeight) &&
		    rect.right <= (window.innerWidth || document. documentElement.clientWidth)
	  	);
	}

	scrollToStart() {
 
	    jQuery("html, body").animate({ scrollTop: "0" });
	}

	scrollToElement(el, position = 'center') {

		const paddingTop = 180;

		switch(position) {

			case 'start':

				const y = el.getBoundingClientRect().top + window.pageYOffset - paddingTop;

				window.scrollTo({top: y, behavior: 'smooth'});

				break;

			case 'center':

				el.scrollIntoView({behavior: 'smooth', block:  position });
				break;

			default:
				break;
		}
	}

	formatPhone(value) {
			
		if(value.length == 11) {
			return value.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
		} 

		if(value.length == 10) {
			return value.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
		}

		return value;		
	}

	truncateString(str, num) {

  		if (str.length <= num) {
		    return str;
		}

  		return str.slice(0, num) + '...';
	}

	formatTwoDigits(number) {

		return String('0' + number).slice(-2);
	}

	monthEN(index) {
		const months = ["January", "February", "March", "April",
				"May", "June", "July", "August", "September",
				"October", "November", "December"];
		
		return months[index];
	}

	monthPT(index) {
		const months = ["Janeiro", "Fevereiro", "Março", "Abril",
				"Maio", "Junho", "Julho", "Agosto", "Setembro",
				"Outubro", "Novembro", "Dezembro"];

		return months[index];
	}

	formatDate(date, lang) {

		var dateObj = new Date(date);
		let month = lang == 'pt' ? this.monthPT(dateObj.getMonth()) : this.monthEN(dateObj.getMonth());
		return month + ' ' + this.formatTwoDigits(dateObj.getDate() + 1) + ', ' + dateObj.getFullYear();
	}

	disableScroll() {

		const html = jQuery('html');
		html.removeClass('with-smooth');
    	var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    	var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        window.onscroll = function() {
        	
            window.scrollTo(scrollLeft, scrollTop);
        };
	}

	enableScroll() {

		const html = jQuery('html');
		html.addClass('with-smooth');
    	window.onscroll = function() {};
	}

	getCookie(name) {

		if (!document.cookie) {
	    	return null;
	  	}

	  	const xsrfCookies = document.cookie.split(';')
	    .map(c => c.trim())
	    .filter(c => c.startsWith(name + '='));

	  	if (xsrfCookies.length === 0) {
		    return null;
	  	}

	  	return decodeURIComponent(xsrfCookies[0].split('=')[1]);
	}

	setCookie(key, value) {

		const d = new Date();
		d.setTime(d.getTime() + (180*24*60*60*1000)); // expires in 180 days
		let expires = "expires="+ d.toUTCString();
		document.cookie = key + "=" + value + ";" + expires + ";path=/";
	}

	removeAcentos(text) {   

	    text = text.toLowerCase();                                                         
	    text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
	    text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
	    text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
	    text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
	    text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
	    text = text.replace(new RegExp('[Ç]','gi'), 'c');
	    return text;                 
	}

	getURLParam(string) {

	    const url = new URL(location.href);
	    const params = new URLSearchParams(url.search.slice(1));
	    if(params.get(string)) {
	        return params.get(string);
	    }

	    return false;
	}

	apenasNumeros(numero) {
		return numero.replace(/\D/g, '');
	}
}

export default new Utils();